import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  flag: {
    marginRight: 4,
    marginTop: 2,
    height: 32,
    width: 32,
    fontSize: '28px',
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const Country = React.memo(function Country(props) {
  const { value } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.flag}>{countryToFlag(value)}</span>
      <span className={classes.label}>{value}</span>
    </div>
  );
});

function renderCountry(params) {
  return <Country value={params.value} />;
}

export default renderCountry