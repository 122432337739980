export const LOGIN_ROUTE = "/Login"
export const DASHBOARD_ROUTE = "/Dashboard"
export const PERSONAL_NEW_CUSTOMER_ROUTE = "/PersonalNew"
export const BUSINESS_DASHBOARD_ROUTE = "/BusinessDashboard"
export const BUSINESS_NEW_CUSTOMER_ROUTE = "/BusinessNew"
export const APPLICATION_ROUTE = "/Application/"
export const BUSINESS_APPLICATION_ROUTE = "/Company/"

export const CURRENCIES = [
  'USD', 'EUR', 'GBP', 'JPY', 'HKD', 'SGD', 'RUB', 'CHF', 'CAD', 'AUD'
]

export const AMOUNTS = [
  '0 - 1', '2 - 5', '6 - 10', '10+'
]


export const LABELS = {
  state_id: 'US ID with Signature',
  employment: 'Employment Verification',
  discharge: 'Discharge Letter',
  passport: 'Passport or ID',
  utility_bill: 'Utility Bill',
  w_8ben: 'W8-BEN',
  bank_statement: 'Bank Statement',
  ach_form: 'ACH Form',
  voided_check: 'Voided Check',
  another_id: 'Another ID',
  income_certificate: 'Income Certificate',
  birth_certificate: 'Minor\'s Birth Certificate',
  bank_reference: 'Bank Reference',
  signed_ira: 'Signed IRA Form'
}

export const TOOLTIPS = {
  state_id: 'Passport, Driver’s License or government-issued I.D. If the I.D. has no address, or does not match the address on your application, it will be additionally required to submit address verification, such as a utility bill.',
  ach_form: 'Please upload your physically signed ACH form here. Link to the form can be found on the bottom right side of the page. Or you can upload your signature using the widget on the mobile page.',
  w_8ben: 'We recommend you submit your signature using the mobile widget. Otherwise please download this form, sign it and then upload.',
  passport: 'Submit a clear photo of your Passport. The signature on your Passport must be visible.',
  utility_bill: 'Submit a recent electricity, water or gas bill which clearly displays your name and address. Must be dated within 90 days. If you do not have Utility bill you may submit Phone, Cable or Credit card bill.',
  bank_statement: 'Submit a full-copy of your bank statement dated within 60 days. Letter must be issued by your bank and state your account standing.',
  employment: 'Submit a recent pay slip or a letter from your employer verifying your name, job title and duration of employment. Must be dated within 60 days.',
  income_certificate: 'Clients from Russian Federation should submit the 2-NDFL form.'
}