import React, {useState, useEffect} from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import LinearProgress from '@material-ui/core/LinearProgress';
import api from '../api'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog: {
    minWidth: 900
  }
}));

function downloadBase64File(data) {
  if (!data.file) {
    return
  }
  // const linkSource = `data:${data.type};base64,${data.file}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = data.file;
  downloadLink.download = data.filename;
  downloadLink.click();
}

function DocumentModal({uid, handleClose}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const [data, setData] = useState('')
  const [error, setError] = useState('')
  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      try {
        const result = await api.base.document({uid});
        if (result && result.file){
          setData(result)
        }
      } catch (e) {
        setError(e.message)
      }
      // setIsLoading(false);
    };
    if (uid) {
      fetchData();
    }

  }, [uid]);
  return (
    <Dialog fullScreen={fullScreen} open={Boolean(uid)} onClose={handleClose} className={classes.dialog}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" onClick={() => {downloadBase64File(data)}} aria-label="close">
            <SaveIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Document View
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {data && data.type.startsWith("image/") &&
        <img src={data.file} height="100%" />}
        {data && data.type === "application/pdf" &&
        <Document file={data.file} >
          <Page pageNumber={1} />
        </Document>
        }
        {!data &&
        <LinearProgress />
        }
      </DialogContent>
    </Dialog>
  )
}

export default DocumentModal

//<iframe src={data} height="100%" width="100%" />