import {FORM_ERROR} from "final-form"
import countries from './data/countries.json'

export const formError = (data) => {
  const errors = data.error || {}
  const nfe = errors.non_field_errors || []
  if (nfe.length > 0) {
    errors[FORM_ERROR] = nfe.join(', ')
  }
  return errors
}

export const getCountryName = name => {
  if (!name) {
    return ''
  }
  const country = countries.find(x => x.value === name)
  return country ? country.label : ''
}