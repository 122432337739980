import React from 'react'

import { Form, FormSpy } from 'react-final-form'
import { email, requiredAll } from '../../form/validation';
import { TextField } from 'mui-rff'
import FormFeedback from '../../form/FormFeedback';
import { formError } from '../../form/utils';
import { makeStyles } from '@material-ui/core/styles';
import MakeAsyncFunction from "react-redux-promise-listener";
import PublicLayout from "../../layout/PublicLayout";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Typography from "../../components/Typography";
import RegisterIcon from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { promiseListener } from "../../redux/store";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL
} from "../../redux/modules/base";



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    flex: 1,
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.secondary.dark
  },
  submitButton: {
    marginTop: theme.spacing(2)
  },
  border: {
    borderBottom: "1px solid black",
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "center"
  }
}))



const validate = values => {
  const errors = requiredAll(['email', 'password'], values);
  if (!errors.email) {
    const emailError = email(values.email, values);
    if (emailError) {
      errors.email = email(values.email, values);
    }
  }
  return errors;
};

function Login () {
  const classes = useStyles()
  return (
    <PublicLayout>
      <div className={classes.root}>
        <MakeAsyncFunction
          listener={promiseListener}
          start={LOGIN}
          resolve={LOGIN_SUCCESS}
          reject={LOGIN_FAIL}
          getPayload={(a) => formError(a.result)}
        >
          {onSubmit => (
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, submitting, pristine, submitError}) => (
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                  <div className={classes.header}>
                    <Typography variant="body2">
                      Backoffice
                    </Typography>
                  </div>
                  <div className={classes.border} />
                  <TextField
                    autoComplete="email"
                    variant="outlined"
                    autoFocus
                    disabled={submitting}
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    required
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    disabled={submitting}
                    required
                    name="password"
                    autoComplete="current-password"
                    label="Password"
                    type="password"
                    margin="normal"
                  />
                  <Box className={classes.feedback}>
                    <FormFeedback error>
                      {submitError}
                    </FormFeedback>
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}
                    disabled={submitting}
                    size="large"
                    color="primary"
                    startIcon={<RegisterIcon />}
                    fullWidth
                  >
                    {submitting ? 'In progress…' : 'Sign In'}
                  </Button>
                </form>
              )}
            </Form>)
          }
        </MakeAsyncFunction>
      </div>
    </PublicLayout>
  )
}

export default Login;