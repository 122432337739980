import React, {useState, useEffect} from 'react'

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PrivateLayout from "../../layout/PrivateLayout";
import Alert from '@material-ui/lab/Alert';
import FormWidget from '../../form/FormWidget';

import {getWidget, submitWidgetData} from "../../api/base";

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
})


function NewCustomer ({classes, config, isBusiness=false}) {
  const [data, setData] = useState({});
  const [submitData, setSubmitData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const getWidgetResult = await getWidget({isBusiness});
        setData(getWidgetResult);

      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [config.bizWidgetId]);

  const handleSubmit = async (formData) => {
    const submitData = {
      form: formData,
      isBusiness
    }

    const result = await submitWidgetData(submitData)
    setSubmitData(result);
  }

  return (
    <PrivateLayout>
      <div className={classes.root}>
        <div style={{marginRight: 12}}>
          {isError && (
            <Alert variant="danger">
              <Typography variant="h5">Error</Typography>
              <p>Please contact support.</p>

            </Alert>
          )}

          {isLoading && (
            <Alert variant="info">
              <Typography variant="h5">Loading...</Typography>
              <p>
                The questionnaire is loading.
              </p>
            </Alert>
          )}
          {!isError && !isError && data.form && !submitData.isCompleted && (
            <FormWidget form={data.form} submitHandler={handleSubmit} />
          )}
          {submitData.isCompleted &&  (
            <Alert variant="success">
              <Typography variant="h5">The form has been submitted!</Typography>
            </Alert>
          )}
        </div>
      </div>
    </PrivateLayout>
  )
}

export default withStyles(styles)(NewCustomer)