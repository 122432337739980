import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {DASHBOARD_ROUTE} from "../const";

const HomeRoute = ({ logging, authenticated, component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (logging) return <div>Logging in...</div>
    return authenticated
      ? (<Redirect to={{pathname: DASHBOARD_ROUTE}} />)
      : (React.createElement(component, { ...props, logging, authenticated }))
  }} />
)

// const HomeRoute = ({ config, logging, authenticated, component, client, ...rest }) => (
//   <Route {...rest} render={(props) => {
//     if (logging) return <div>Logging in...</div>
//     return (React.createElement(component, { ...props, config, client, logging, authenticated }))
//   }} />
// )


export default HomeRoute