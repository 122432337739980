import React from 'react';
import LogoImg from "./assets/long.png";

const Logo = (props) => {
  return (
    <img
      style={{height: 50}}
      alt="Logo"
      src={LogoImg}
      {...props}
    />
  );
};

export default Logo;
