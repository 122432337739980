import apiClient from './apiClient'
import {removeEmpty} from './utils'

import config from '../config'


export function register (formData) {
  //const data = removeEmpty(removeEmpty({...formData}))
  const data = removeEmpty({...formData})
  return apiClient.post('auth/register/', {data})
}

export function login (data) {
  return apiClient.post('auth/login-admin/', {data})
}

export function authorizeWithToken () {
  return apiClient.get('auth/auth-token-admin/')
}

export function logout (data) {
  return apiClient.post('auth/logout/', {data})
}

export function getTicket () {
  return apiClient.get('auth/sign/')
}


export function dashboard (data) {
  return apiClient.get('bo/dashboard/')
}

export function submit (formData) {
  const data = removeEmpty({...formData})
  return apiClient.post('bo/submit/', {data})
}

export function uploadFile (uid, data) {
  return apiClient.post('bo/document-upload/' + uid + '/', data)
}

export function application (data) {
  return apiClient.get('bo/application/' + data.uid + '/')
}
export function company (data) {
  return apiClient.get('bo/company/' + data.uid + '/')
}

export function document (data) {
  return apiClient.get('bo/document/' + data.uid + '/')
}

export function getWidget (data) {
  return apiClient.post('bo/widget/get/', {data})
}

export function submitWidgetData (data) {
  return apiClient.post('bo/widget/submit-data/', {data})
}


// export function getPersonalWidget () {
//   return apiClient.get('admin/widget/')
// }
// export function getBusinessWidget () {
//   return apiClient.get('admin/widget/' + config.bizWidgetId + '/')
// }
//
// export function submitBusinessWidgetData (data) {
//   return apiClient.post('bo/widget/' + config.bizWidgetId + '/submit-data/', data)
// }
//
// export function submitPersonalWidgetData (data) {
//   return apiClient.post('bo/widget/' + config.personalWidgetId + '/submit-data/', data)
// }
