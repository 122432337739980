import React, {useMemo} from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import PrivateLayout from "../../layout/PrivateLayout";
import {useSelector} from "react-redux";
import {APPLICATION_ROUTE, PERSONAL_NEW_CUSTOMER_ROUTE} from "../../const";
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom"
import {renderCountry} from "../../components";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flex: 1,
    // flexDirection: 'column',
    // alignItems: 'stretch'
    width: '100%',
    height: 820
  },
  table: {
    // display: 'flex',
    // flex: 1,
    backgroundColor: '#FFFFFF'
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.secondary.dark
  }
}))

const CellChip = React.memo((props) => <Chip color="primary" size="small" label={props.value}/>)
const CellBoolIcon = React.memo((props) => (
  props.value
  ? <CheckIcon color="primary" />
  : <ClearIcon color="action" />
  )
)
// const IconLinkButton = React.memo((props) => (
//   <IconButton color="primary" ><ExitToAppIcon /></IconButton>
//   ))
const renderChip = (params) => (
  params.value.map((cv, i) => <CellChip value={cv} key={i} />)
)
const renderBoolIcon = (params) => <CellBoolIcon value={params.value} />

const IconLink = (params) => {
  const history = useHistory()
  return (
    <IconButton color="primary" onClick={() => {history.push(APPLICATION_ROUTE + params.value)}}>
      <ExitToAppIcon />
    </IconButton>
  )
}

const tableColumns = [
  // { headerName: 'ID', field: 'id' },
  { field: 'id', hide: true },
  {
    headerName: ' ', field: 'uid', width: 65, renderCell: IconLink,
    resizable: false, sortable: false, disableColumnMenu: true
  },
  { headerName: 'Name', field: 'name', width: 180 },
  { headerName: 'Email', field: 'email', width: 210 },
  { headerName: 'Phone', field: 'phone', width: 120 },
  { headerName: 'Status', field: 'status', width: 120 },
  { headerName: 'Ext. Id', field: 'externalId', width: 120 },
  {
    headerName: 'Client Of',
    field: 'companyName',
    renderCell: renderChip,
    width: 110
  },
  {
    headerName: 'Owners',
    field: 'owners',
    renderCell: renderChip,
    width: 110
  },
  {
    headerName: 'Citizenship',
    field: 'citizenship',
    renderCell: renderCountry,
    width: 123
  },
  {
    headerName: 'Residency',
    field: 'residency',
    renderCell: renderCountry,
    width: 121
  },
  {
    headerName: 'Completed?',
    field: 'isCompleted',
    renderCell: renderBoolIcon,
    width: 130,
  },
  {
    headerName: 'Checked?',
    field: 'isChecked',
    renderCell: renderBoolIcon,
    width: 120
  },
]


const Dashboard = () => {
  const classes = useStyles()
  const clients = useSelector((state) => state.base.clients)
  const tableClients = useMemo(() => JSON.parse(JSON.stringify(clients)), [clients])

  return (
    <PrivateLayout>
      <div className={classes.root}>
        <div className={classes.header}>
          <Link component={RouterLink} to={PERSONAL_NEW_CUSTOMER_ROUTE}>
            New Client
          </Link>
        </div>
        <DataGrid
          rows={tableClients}
          columns={tableColumns}
          className={classes.table}
          pageSize={12}
          // onRowClick={({row}) => {history.push(APPLICATION_ROUTE + row.uid)}}
          showToolbar={false}
          sortModel={[
            {
              field: 'id',
              sort: 'desc',
            },
          ]}
        />
        {/*<MaterialTable*/}
        {/*  columns={tableColumns}*/}
        {/*  data={tableClients}*/}
        {/*  title="Applications"*/}
        {/*  options={{pageSize: 12, pageSizeOptions: [12, 25, 50]}}*/}
        {/*  className={classes.table}*/}
        {/*  actions={[*/}
        {/*    {*/}
        {/*      icon: 'visibility',*/}
        {/*      tooltip: 'Open',*/}
        {/*      onClick: (event, rowData) => history.push(APPLICATION_ROUTE + rowData.uid)*/}
        {/*    }*/}
        {/*  ]}*/}
        {/*/>*/}
      </div>
    </PrivateLayout>
  )
}

export default Dashboard