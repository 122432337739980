import isEmpty from 'lodash.isempty'
import isObject from 'lodash.isobject'
import transform from 'lodash.transform'

let WINDOW_ENV = {}

if (typeof (window) !== 'undefined') {
  WINDOW_ENV = window
}

export function formError (error) {
  return (
    {_error: error.message || error}
  )
}

// onSubmit(credentials) {
//   return this.props
//     .logIn(credentials)
//     .then((result) => {
//       console.log(result);
//     })
//     .catch((error) => {
//       console.log(error);
//       return { [FORM_ERROR]: 'Login Failed' };
//     });
// }
export function getParam (field, url) {
  const href = url || WINDOW_ENV.location.href
  const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
  const string = reg.exec(href)
  return string ? string[1] : null
}



export function removeEmpty(el) {
  function internalClean(el) {
    return transform(el, function(result, value, key) {
      let isCollection = isObject(value);
      let cleaned = isCollection ? internalClean(value) : value;

      if ((isCollection && isEmpty(cleaned)) || cleaned === null || cleaned === '') {
        return;
      }

      Array.isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
    });
  }

  return isObject(el) ? internalClean(el) : el;
}