import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    // primary: {
    //   main: colors.indigo[500]
    // },
    // secondary: {
    //   main: colors.indigo[500]
    // },
    primary: {
      light: "rgba(61, 90, 254, 0.81)",
      main: "#536dfe",
      dark: "#1135fd"
    },
    secondary: {
      light: "#536dfe",
      main: "#AeAeAe",
      dark: "#9e9e9e"
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

export default theme;
