import React, {useState, useEffect} from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Grid from "@material-ui/core/Grid";



import {DropzoneArea} from 'material-ui-dropzone';

import {uploadFile} from "../api/base";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog: {
    minWidth: 900
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));



function UploadModal({uid, handleClose, type, open}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const [data, setData] = useState('')
  const [file, setFile] = useState(null)
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [filetype, setFiletype] = useState('');
  const handleSave = async () => {
    setError('')
    setMessage('')
    try {
      if (!filetype) {
        setError('Please set File Type')
        return
      }
      if (!file) {
        setError('Please set File')
        return
      }
      const result = await uploadFile(uid, {fields: [{key: 'file_tag', value: filetype}], files: [{key: 'file', value: file}]});
      // console.log(result)
      if (result && !result.error) {
        setMessage('Upload completed successfully')
      } else if (result  && result.error) {
        setError('Error:' + result.error);
      }
      setFile(null)
      setFiletype('')
    } catch (e) {
      setError('Bad format');
    } finally {
      // setOpen(false);
    }
  };

  const handleChange = (event) => {
    setFiletype(event.target.value);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} className={classes.dialog}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Upload Document
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {error &&
        <Alert severity="error">{error}</Alert>}
        {message &&
        <Alert severity="success">{message}</Alert>}
        <FormControl className={classes.formControl}>
          <InputLabel id="file-select">File Type</InputLabel>
          <Select
            labelId="file-select-label"
            id="file-select-select"
            value={filetype}
            onChange={handleChange}
          >
            {type === "personal" &&
            [
            <MenuItem value="IDENTITY" key="IDENTITY">Passport/ID</MenuItem>,
            <MenuItem value="address_personal" key="address_personal">Proof of Address</MenuItem>,
            <MenuItem value="bank_statement_personal" key="bank_statement_personal">Bank Statement</MenuItem>
            ]}
            {type === "company" &&
            [
            <MenuItem value="director_id" key="director_id">Director's ID</MenuItem>,
            <MenuItem value="director_address" key="director_address">Director's Address</MenuItem>,
            <MenuItem value="ubo_id" key="ubo_id">UBO's ID</MenuItem>,
            <MenuItem value="ubo_address" key="ubo_address">UBO's Address</MenuItem>,
            <MenuItem value="certificate_registration" key="certificate_registration">Certificate of Registration</MenuItem>,
            <MenuItem value="certificate_good" key="certificate_good">Certificate of Good Standing</MenuItem>,
            <MenuItem value="address_company" key="address_company">Proof of Address Company</MenuItem>,
            <MenuItem value="bank_statement_company" key="bank_statement_company">Bank Statement Company</MenuItem>,
            <MenuItem value="kyc_check" key="kyc_check">KYC Check</MenuItem>
            ]}
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <DropzoneArea
          filesLimit={1}
          maxFileSize={50000000}
          onChange={(fileObjs) => {setFile(fileObjs[0])}}
          onDelete={(fileObj) => {setFile(null)}}/>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          UPLOAD
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploadModal