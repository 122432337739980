import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider } from 'react-redux'
import {authorizeWithToken} from './redux/modules/base'
import {findToken, saveToken, setStore} from './api'
import { ConnectedRouter } from 'connected-react-router'
import Routes from './Routes'
import store, {history} from './redux/store'

setStore(store)

if (typeof (window) !== 'undefined') {
  const loc = window.location
  const path = loc ? loc.pathname : null
  let savedToken = findToken(loc.href)
  if (savedToken) {
    saveToken(savedToken)
    store.dispatch(authorizeWithToken(path))
  }
}

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
