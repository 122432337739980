import {
  REGISTER, REGISTER_SUCCESS, REGISTER_FAIL, REGISTER_FORM,
  LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, FORM_LOGIN,
  AUTH, AUTH_SUCCESS, AUTH_FAIL,
  LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL,
  DASHBOARD, DASHBOARD_SUCCESS, DASHBOARD_FAIL,
  SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL,
  UPFILE, UPFILE_SUCCESS, UPFILE_FAIL
} from '../modules/base'

import {setData} from '../modules/base'

import {take, call, put, fork, select} from 'redux-saga/effects'
// import {push} from 'react-router-redux'
import { push } from 'connected-react-router'


import {callApi} from './utils'
import api, {setToken, removeToken, formError} from '../../api'
import {DASHBOARD_ROUTE, APPLICATION_ROUTE, LOGIN_ROUTE} from "../../const";

function * postLogin (data, path) {
  setToken(data.token)
  // yield put(setData(data))
  // yield put(push(data.next))
  // if (data.user.client.isBusiness) {
  //   yield put(push('/business'))
  // } else {
  //   yield put(push('/personal'))
  // }
  if (path && path !== '/' && path !== LOGIN_ROUTE && path.startsWith(APPLICATION_ROUTE)) {
    yield put(push(path))
  } else {
    yield put(push(DASHBOARD_ROUTE))
  }

}

export function * register (data) {
  const {res, error} = yield callApi(api.base.register, data, REGISTER_SUCCESS, REGISTER_FAIL)
  if (res && res.token && res.token.length > 10 && !error) {
    return res
  } else {
    return false
  }
}

export function * registerFlow () {
  while (true) {
    let request = yield take(REGISTER)
    let success = yield call(register, request.payload)
    if (success) {
      yield postLogin(success)
    }
  }
}

export function * login (data) {
  const {res, error} = yield callApi(api.base.login, data, LOGIN_SUCCESS, LOGIN_FAIL)
  if (res && res.token && res.token.length > 10 && !error) {
    return res
  } else {
    return false
  }
}

export function * loginFlow () {
  while (true) {
    let request = yield take(LOGIN)
    let success = yield call(login, request.payload)
    if (success) {
      yield postLogin(success)
    }
  }
}

export function * authorizeWithToken () {
  const {res, error} = yield callApi(api.base.authorizeWithToken, {}, AUTH_SUCCESS, AUTH_FAIL)
  if (res && res.token && res.token.length > 10 && !error) {
    return res
  } else {
    return false
  }
}

export function * authorizeWithTokenFlow () {
  while (true) {
    let action = yield take(AUTH)
    let success = yield call(authorizeWithToken)
    if (success) {
      yield postLogin(success, action.payload.path)
    }
  }
}

export function * logout () {
  yield callApi(api.base.logout, {}, LOGOUT_SUCCESS, LOGOUT_FAIL)
}

export function * logoutFlow () {
  while (true) {
    yield take(LOGOUT)
    yield call(logout)
    removeToken()
  }
}


export function * dashboard (data) {
  const {res} = yield callApi(api.base.dashboard, data, DASHBOARD_SUCCESS, DASHBOARD_FAIL)
  if (res) {
    return res
  } else {
    return false
  }
}

export function * dashboardFlow () {
  while (true) {
    let request = yield take(DASHBOARD)
    let success = yield call(dashboard, request.payload)
    if (success && success.currentAccount && success.currentAccount.next) {
      if (request.payload && request.payload.first) {
        yield put(push('/' + success.currentAccount.next))
      }
    }
  }
}


export function * submit (data) {
  const {res, error} = yield callApi(api.base.submit, data, SUBMIT_SUCCESS, SUBMIT_FAIL)
  if (res) {
    return res
  } else {
    return false
  }
}

export function * submitFlow () {
  while (true) {
    let request = yield take(SUBMIT)
    yield call(submit, request.payload)
  }
}

export function * uploadFile (data) {
  yield callApi(api.base.uploadFile, data, UPFILE_SUCCESS, UPFILE_FAIL)
}

export function * uploadFileFlow () {
  while (true) {
    let request = yield take(UPFILE)
    yield call(uploadFile, request.payload)
  }
}

export default function * root () {
  yield fork(registerFlow)
  yield fork(loginFlow)
  yield fork(authorizeWithTokenFlow)
  yield fork(logoutFlow)
  yield fork(dashboardFlow)
  yield fork(submitFlow)
  yield fork(uploadFileFlow)
}
