import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Background from './assets/london.jpg'
import Logo from "./assets/long.png";



function Agreement() {
  return (
    <Typography variant="body2"  align="center">
      {'By proceeding you agree to our '}
      <Link target="_blank" href="https://recary.co.uk/privacy-policy">
        Privacy Policy
      </Link>
      {' and '}
      <Link target="_blank" href="https://recary.co.uk/terms-conditions">
        Terms & Conditions
      </Link>{'.'}
    </Typography>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://recary.co.uk/">
        Recary Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  logo: {
    maxWidth: 200
  },
  border: {
    borderBottom: "1px solid black",
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
}
}));

export default function PublicLayout({children}) {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={Logo} alt="Logo" />
          </div>
          {children}
          <div className={classes.border} />
          <Agreement />
          <Copyright />
        </div>
      </Grid>
    </Grid>
  );
}