import superagent from 'superagent'
import config from '../config'
import {getParam} from './utils'
import {UNAUTH} from '../redux/modules/base'


let STORAGE_ENV
let WINDOW_ENV = {}

if (typeof (window) !== 'undefined') {
  STORAGE_ENV = window.localStorage
  WINDOW_ENV = window
}

const methods = [ 'get', 'post', 'put', 'patch', 'del' ]

const TOKEN_KEY = 'recary_adm'
const TOKEN_TYPE = 'Token'


export function findToken (url, storage, storageKey) {
  const key = storageKey || TOKEN_KEY
  const tokenStorage = storage || STORAGE_ENV
  const windowToken = WINDOW_ENV['__' + key]
  return tokenStorage.getItem(key) || getParam(key, url) || windowToken
}

export function saveToken (token, storage, storageKey) {
  const key = storageKey || TOKEN_KEY
  const tokenStorage = storage || STORAGE_ENV
  return tokenStorage.setItem(key, token)
}

export function deleteToken (storage, storageKey) {
  const key = storageKey || TOKEN_KEY
  const tokenStorage = storage || STORAGE_ENV
  return tokenStorage.removeItem(key)
}

export function getToken (storage, storageKey) {
  const key = storageKey || TOKEN_KEY
  const tokenStorage = storage || STORAGE_ENV
  return tokenStorage.getItem(key)
}

export function formatUrl (path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path
  if (!config.isProduction) {
    // Prepend host and port of the API server to the path.
    return `http://${config.apiHost}:${config.apiPort + config.apiPrefix + adjustedPath}`
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return `https://${config.apiHost}${config.apiPrefix}${adjustedPath}`
}

export const getFormUrl = (formTag, uid) => `app/account/${uid}/download-form/${formTag}/`

export const downloadUrl = (formTag, uid, ticket) => {
  if (WINDOW_ENV) {
    let link = document.createElement('a')
    const url = getFormUrl(formTag, uid)
    const uri = formatUrl(url + '?ticket=' + ticket)
    if (typeof link.download === 'string') {
      link.href = uri
      link.setAttribute('download', '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.location.replace(uri)
    }
    return true
  }
}

export class ApiClient {
  constructor (req) {
    const apiClientObject = this
    methods.forEach(method => {
      this[method] =
        (path, { params, data, headers, files, fields } = {}, isForm=false) => new Promise((resolve, reject) => {

        const request = superagent[method](formatUrl(path))
        const isFormReq = isForm || !!data

        if (params) {
          request.query(params)
        }

        if (headers) {
          request.set(headers)
        }
        const token = apiClientObject.token || getToken()
        if (token) {
          request.set('Authorization', `${TOKEN_TYPE} ${token}`)
        }
        if (files) {
          files.forEach(file => request.attach(file.key, file.value))
        }

        if (fields) {
          fields.forEach(item => request.field(item.key, item.value))
        }

        if (data) {
          request.send(data)
        }

        request.end((err, res) => {
          const bodyError = res && res.body && res.body.error
          const formError = res && res.body && res.body.non_field_errors && res.body.non_field_errors.join(', ')
          const jsonError = bodyError || formError
          const errorBody = res && res.body && typeof res.body === 'object' && Object.keys(res.body).length > 0
          // TODO: parse errors properly
          // if (err) {
          //   return reject(err)
          if (err || jsonError) {
            if (res && res.statusCode === 401) {
              apiClientObject.setToken(undefined)
              deleteToken()
              if (this.store) {
                this.store.dispatch({type: UNAUTH})
              }
              // TODO: dispath unauth in callapi
            }
            if (isFormReq) {
              if (errorBody) {
                return resolve({error: res.body, data: {}})
              } else {
                return resolve({error: {non_field_errors: ['Server or connection issues. Please try again or contact support.']}, data: {}})
              }
            } else {
              const error = new Error(jsonError || err || 'Error.')
              error.res = res
              return reject(error)
            }
          } else {
            return resolve((res && res.body) || {})
          }
        })
      })
    })
  }
  setToken (token) {
    this.token = token
  }
  setStore (store) {
    this.store = store
  }
}

const apiClient = new ApiClient()

export function setToken (token, storage, storageKey) {
  apiClient.setToken(token)
  saveToken(token, storage, storageKey)
}

export function setStore (store) {
  apiClient.setStore(store)
}

export function removeToken (storage, storageKey) {
  apiClient.setToken(undefined)
  deleteToken(storage, storageKey)
}

export default apiClient
