import React, {useState, useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import PrivateLayout from "../../layout/PrivateLayout";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ManagerIcon from '@material-ui/icons/AccountCircle';
import BackIcon from '@material-ui/icons/ArrowBack';
import EyeIcon from '@material-ui/icons/Visibility';
import LinearProgress from '@material-ui/core/LinearProgress';
import api from '../../api'
import {getCountryName} from "../../form/utils";
import {Box} from "@material-ui/core";
import DocumentModal from "../../components/DocumentModal";
import UploadModal from "../../components/UploadModal";
import {DASHBOARD_ROUTE} from "../../const";

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(2),
    justifyContent: 'space-between'
  },
  profile: {
    // display: 'flex',
    // flex: 1,
    padding: theme.spacing(2),
    minHeight: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  validity: {
    // display: 'flex',
    // flex: 1,
    padding: theme.spacing(2),
    minHeight: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(1),
    minHeight: 400
  },
  section: {
    marginBottom: theme.spacing(0.8)
  },
  sectionHeader: {
    fontWeight: 700,
    marginBottom: theme.spacing(0.4),
    fontSize: "1rem"
  },
  sectionSpan: {
    fontSize: "0.9rem"
  }
}))




const Application = ({history, match}) => {
  const classes = useStyles()
  const [data, setData] = useState({ user: {}, information: {}, check: {}});
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('')
  const [documentUid, setDocumentUid] = useState('')
  const [uploadOpen, setUploadOpen] = useState(false);
  const documentModalClose = () => {setDocumentUid('')}
  const uploadModalClose = () => {setUploadOpen(false); setReload(reload + 1)}
  const uploadModalOpen = () => {setUploadOpen(true)}
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await api.base.application({uid: match.params.uid});
        setData(result);
      } catch (e) {
        console.log('error ', e)
      }
      setIsLoading(false);
    };
    fetchData();
  }, [reload]);

  if (isLoading || !(data && data.user)) {
    return <PrivateLayout><LinearProgress /></PrivateLayout>
  }

  const {user, information: info, check, documents} = data
  const A = info && info.data ? info.data : {}
  const R = check && check.riskScoreInfo ? check.riskScoreInfo : {}
  const documentsSumsub = check && check.documents ? check.documents : []
  const idDocs = check && check.idDocs ? check.idDocs : []
  const extras = [
    {variant: 'outlined', icon: <CloudUploadIcon />, label: "Run Check", disabled: true, action: () => {} },
    {variant: 'outlined', icon: <CloudUploadIcon />, label: "Upload Document", action: uploadModalOpen },
    {variant: 'outlined', icon: <ManagerIcon />, label: "Take Client", disabled: true, action: () => {} },
  ]
  return (
    <PrivateLayout back={DASHBOARD_ROUTE} extras={extras}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
          <AppBar position="static" elevation={0}>
            <Toolbar className="px-8">
              <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                General Information
              </Typography>
            </Toolbar>
          </AppBar>

          <CardContent>
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>Name</Typography>
              <Typography className={classes.sectionSpan}>{user.first_name} {user.last_name}</Typography>
              <Typography variant="subtitle2">({A.firstName} {A.lastName} {A.hasOtherNames && A.otherNames})</Typography>
            </div>

            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>Birth</Typography>
              <Typography className={classes.sectionSpan}>{A.dateOfBirth}</Typography>
              <Typography className={classes.sectionSpan}>{getCountryName(A.birthCountry)}</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>Citizenship</Typography>
              <Typography className={classes.sectionSpan}>{getCountryName(A.citizenship)}</Typography>
              <Typography className={classes.sectionSpan}>{A.idType} {A.idNo}</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>Tax Residency</Typography>
              <Typography className={classes.sectionSpan}>{getCountryName(A.taxResidency)}</Typography>
              <Typography className={classes.sectionSpan}>{A.taxNo}</Typography>
            </div>
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <AppBar position="static" elevation={0}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                  Contact
                </Typography>
              </Toolbar>
            </AppBar>
            <CardContent>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Contact</Typography>
                <Typography className={classes.sectionSpan}>{user.email}</Typography>
                <Typography className={classes.sectionSpan}>{A.phoneNumber}</Typography>
              </div>
              {A.address &&
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Address</Typography>
                <Typography className={classes.sectionSpan}>{A.address.line1}</Typography>
                {A.addressLine2 &&
                <Typography className={classes.sectionSpan}>{A.address.line2}</Typography>}
                <Typography className={classes.sectionSpan}>{A.address.city} {A.address.postcode}</Typography>
                <Typography className={classes.sectionSpan}>{getCountryName(A.address.country)}</Typography>
              </div>}
              {A.address &&
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Lived Here</Typography>
                <Typography className={classes.sectionSpan}>{A.address.years} year(s)</Typography>
              </div>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <AppBar position="static" elevation={0}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                  Agreements & Documents
                </Typography>
              </Toolbar>
            </AppBar>
            <CardContent>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Subscriber</Typography>
                <Typography className={classes.sectionSpan}>{A.Subscriber}/ {A.SubscriberStatus}</Typography>
              </div>
              <div className={classes.section}>
                {documents && documents.length > 0 &&
                <List component="nav">
                  {documents.map((d, i) => (
                    <ListItem button key={i} onClick={() => {setDocumentUid(d.uid)}}>
                      <ListItemIcon>
                        <EyeIcon />
                      </ListItemIcon>
                      <ListItemText primary={d.file_tag + ': ' + d.provider} />
                    </ListItem>
                  ))}
                </List>}
                <Typography className={classes.sectionHeader}>Data and Account Creation</Typography>
                <Typography className={classes.sectionSpan}>{A.agreeData ? 'Yes' : 'No'}</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {A.address && A.address.years  && A.address.years < 3 && A.address.otherAddress &&
        <Grid item xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <AppBar position="static" elevation={0}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                  Previous Address
                </Typography>
              </Toolbar>
            </AppBar>
            <CardContent>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Address</Typography>
                <Typography className={classes.sectionSpan}>{A.address.otherAddress.line1}</Typography>
                {A.address.otherAddress.line2 &&
                <Typography className={classes.sectionSpan}>{A.address.otherAddress.line2}</Typography>}
                <Typography className={classes.sectionSpan}>{A.address.otherAddress.city} {A.address.otherAddress.postcode}</Typography>
                <Typography className={classes.sectionSpan}>{getCountryName(A.address.otherAddress.country)}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Lived Here</Typography>
                <Typography className={classes.sectionSpan}>{A.address.otherAddress.years} year(s)</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>}
        <Grid item xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <AppBar position="static" elevation={0}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                  Employment & Income
                </Typography>
              </Toolbar>
            </AppBar>
            <CardContent>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Status</Typography>
                <Typography className={classes.sectionSpan}>{A.employment}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Employer</Typography>
                <Typography className={classes.sectionSpan}>{A.employerName}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Annual Income</Typography>
                <Typography className={classes.sectionSpan}>{A.annualIncome}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Total Net Worth</Typography>
                <Typography className={classes.sectionSpan}>{A.totalNetWorth}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Source</Typography>
                <Typography className={classes.sectionSpan}>{A.sourceOfIncome}</Typography>
                {A.sourceOfIncome === 'Other' &&
                <Typography className={classes.sectionSpan}>{A.otherIncome}</Typography>}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className={classes.card}>
            <AppBar position="static" elevation={0}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                  Sumsub Check
                </Typography>
              </Toolbar>
            </AppBar>
            <CardContent>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Status</Typography>
                <Typography className={classes.sectionSpan}>{check.status}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Result</Typography>
                <Typography className={classes.sectionSpan}>{check.result}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Score</Typography>
                <Typography className={classes.sectionSpan}>{R.answer} - {R.riskScore}</Typography>
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Documents Status</Typography>
                {documentsSumsub.map((d, i) => (
                  <Typography className={classes.sectionSpan} key={i}>
                    {d.type} - {d.country} - {d.result}
                  </Typography>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
        {idDocs.map((d, i) => (
          <Grid item xs={12} sm={6} lg={4} key={i}>
            <Card className={classes.card}>
              <AppBar position="static" elevation={0}>
                <Toolbar className="px-8">
                  <Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
                    Sumsub Document {i+1}
                  </Typography>
                </Toolbar>
              </AppBar>
              <CardContent>
                <div className={classes.section}>
                  <Typography className={classes.sectionHeader}>{d.idDocType}</Typography>
                  <Typography className={classes.sectionSpan}>{d.number}</Typography>
                  <Typography className={classes.sectionSpan}>{d.gender} - {d.country} - {d.dob}</Typography>
                </div>
                <div className={classes.section}>
                  <Typography className={classes.sectionHeader}>Name</Typography>
                  <Typography className={classes.sectionSpan}>{d.lastName}</Typography>
                  {d.lastNameEn &&
                  <Typography className={classes.sectionSpan}>{d.lastNameEn}</Typography>}
                </div>
                <div className={classes.section}>
                  <Typography className={classes.sectionHeader}>Issued - Valid</Typography>
                  <Typography className={classes.sectionSpan}>{d.issuedDate} - {d.validUntil}</Typography>
                </div>
                <div className={classes.section}>
                  <Typography className={classes.sectionHeader}>Place Of Birth</Typography>
                  <Typography className={classes.sectionSpan}>{d.placeOfBirth}</Typography>
                </div>

              </CardContent>
            </Card>
          </Grid>
        ))}
        <DocumentModal uid={documentUid} handleClose={documentModalClose} />
        <UploadModal open={uploadOpen} uid={match.params.uid} handleClose={uploadModalClose} type="personal" />
      </Grid>
    </PrivateLayout>
  )
}

export default Application