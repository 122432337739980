export const REGISTER_FORM = 'registerForm'

export const REGISTER = 'intro/base/REGISTER'
export const REGISTER_SUCCESS = 'intro/base/REGISTER_SUCCESS'
export const REGISTER_FAIL = 'intro/base/REGISTER_FAIL'

export const FORM_LOGIN = 'loginForm'
export const LOGIN = 'intro/base/LOGIN'
export const LOGIN_SUCCESS = 'intro/base/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'intro/base/LOGIN_FAIL'

export const ADMIN = 'intro/base/ADMIN'
export const AUTH = 'intro/base/AUTH'
export const AUTH_SUCCESS = 'intro/base/AUTH_SUCCESS'
export const AUTH_FAIL = 'intro/base/AUTH_FAIL'
export const UNAUTH = 'intro/base/UNAUTH'

export const LOGOUT = 'intro/base/LOGOUT'
export const LOGOUT_SUCCESS = 'intro/base/LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'intro/base/LOGOUT_FAIL'

export const SET_DATA = 'intro/base/SET_DATA'

export const DASHBOARD = 'intro/base/DASHBOARD'
export const DASHBOARD_SUCCESS = 'intro/base/DASHBOARD_SUCCESS'
export const DASHBOARD_FAIL = 'intro/base/DASHBOARD_FAIL'

export const SUBMIT = 'intro/base/SUBMIT'
export const SUBMIT_SUCCESS = 'intro/base/SUBMIT_SUCCESS'
export const SUBMIT_FAIL = 'intro/base/SUBMIT_FAIL'

export const UPFILE = 'intro/app/UPFILE'
export const UPFILE_SUCCESS = 'intro/app/UPFILE_SUCCESS'
export const UPFILE_FAIL = 'intro/app/UPFILE_FAIL'
export const CLIENT = 'intro/base/CLIENT'
export const CLIENT_SUCCESS = 'intro/base/CLIENT_SUCCESS'
export const CLIENT_FAIL = 'intro/base/CLIENT_FAIL'

const initialState = {
  user: null,
  token: null,
  loggingIn: false,
  loginError: '',
  loginPayload: {},
  registeringIn: false,
  isAdmin: false,
  clients: [],
  businesses: [],
  uploadReq: {},
  uploadError: {},
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case UNAUTH:
      return {
        user: null,
        token: null,
        isAdmin: false
      }
    case ADMIN:
      return {
        isAdmin: true
      }
    case REGISTER:
      return {
        ...state,
        registeringIn: true
      }
    case REGISTER_SUCCESS:
      if (action.result.error) {
        return {...state, registeringIn: false}
      }
      return {
        ...state,
        user: action.result.user,
        token: action.result.token,
        clients: action.result.clients,
        registeringIn: false
      }
    case REGISTER_FAIL:
      return {
        ...state,
        registeringIn: false
      }
    case LOGIN:
      return {
        ...state,
        loggingIn: true
      }
    case LOGIN_SUCCESS:
      if (action.result.error) {
        return {...state, loggingIn: false}
      }
      return {
        ...state,
        loginResult: action.result,
        user: action.result.user,
        clients: action.result.user ? action.result.clients : [],
        businesses: action.result.user ? action.result.businesses : [],
        token: action.result.token,
        loggingIn: false
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false
      }
    case AUTH:
      return {
        ...state,
        authIn: true
      }
    case AUTH_SUCCESS:
      if (action.result.error) {
        return {...state, authIn: false}
      }
      return {
        ...state,
        user: action.result.user,
        clients: action.result.user ? action.result.clients : [],
        businesses: action.result.user ? action.result.businesses : [],
        token: action.result.token,
        authIn: false
      }
    case AUTH_FAIL:
      return {
        ...state,
        authIn: false
      }
    case LOGOUT_SUCCESS:
      return {
        user: null,
        token: null
      }
    case LOGOUT_FAIL:
      return {
        user: null,
        token: null
      }
    case SET_DATA:
      return {
        ...state,
        clients: action.payload,
      }
    case DASHBOARD:
      return {
        ...state,
        dashboardReq: true,
        dashboardError: undefined
      }
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardReq: false,
        clients: action.result
      }
    case DASHBOARD_FAIL:
      return {
        ...state,
        dashboardReq: false,
        dashboardError: action.error
      }
    case SUBMIT:
      return {
        ...state,
        submitReq: true,
        submitError: undefined
      }
    case SUBMIT_SUCCESS:
      return {
        ...state,
        submitReq: false,
        clients: action.result
      }
    case SUBMIT_FAIL:
      return {
        ...state,
        submitReq: false,
        submitError: action.error
      }
    case CLIENT:
      return {
        ...state,
        clientReq: true,
        clientError: undefined
      }
    case CLIENT_SUCCESS:
      return {
        ...state,
        clientReq: false,
        client: action.result
      }
    case CLIENT_FAIL:
      return {
        ...state,
        clientReq: false,
        clientError: action.error
      }
    case UPFILE:
      return {
        ...state,
        uploadReq: {
          ...state.uploadReq,
          [action.payload.tag]: true
        },
        uploadError: {
          ...state.uploadError,
          [action.payload.tag]: null
        }
      }
    case UPFILE_SUCCESS:
      return {
        ...state,
        uploadReq: {
          ...state.uploadReq,
          [action.payload.tag]: false
        },
        clients: action.result
      }
    case UPFILE_FAIL:
      return {
        ...state,
        uploadReq: {
          ...state.uploadReq,
          [action.payload.tag]: false
        },
        uploadError: {
          ...state.uploadError,
          [action.payload.tag]: action.error
        }
      }
    default:
      return state
  }
}

export function register (data) {
  return {
    type: REGISTER,
    payload: data
  }
}

export function login (data) {
  return {
    type: LOGIN,
    payload: data
  }
}


export function authorizeWithToken (path=null) {
  return {
    type: AUTH,
    payload: {path}
  }
}

export function logout () {
  return {
    type: LOGOUT
  }
}

export function setData (data) {
  return {
    type: SET_DATA,
    payload: data
  }
}

export function dashboard (first) {
  return {
    type: DASHBOARD,
    payload: {first}
  }
}

export function submit (data) {
  return {
    type: SUBMIT,
    payload: data
  }
}

export function uploadFile (data) {
  return {
    type: UPFILE,
    payload: data
  }
}


export function getClient (uid) {
  return {
    type: CLIENT,
    payload: {uid}
  }
}