import React from 'react'
import { Switch } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline';
import {Login, Dashboard, Application, BusinessDashboard, NewCustomer, Company} from './screens'
import {ThemeProvider} from '@material-ui/styles'
import {withStyles} from '@material-ui/core'

import config from './config'
import theme from './theme'
import GlobalStyles from './components/GlobalStyles';
import { connect } from 'react-redux'
import { HomeRoute, PublicRoute, PrivateRoute } from './components'
import * as baseActions from './redux/modules/base'
import {
  LOGIN_ROUTE,
  DASHBOARD_ROUTE,
  APPLICATION_ROUTE,
  BUSINESS_DASHBOARD_ROUTE,
  BUSINESS_NEW_CUSTOMER_ROUTE,
  PERSONAL_NEW_CUSTOMER_ROUTE,
  BUSINESS_APPLICATION_ROUTE
} from "./const";


const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: "100vh",
    flexDirection: 'column',

  },
  container: {
    display: 'flex',
    flex: 1
  },
  paper: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 6),
    },
  },
})

function Routes({classes, ...props}) {

  const {
    user, authIn, logout
  } = props
  const authenticated = !!user
  // const logging = loggingIn || registeringIn || authIn
  const logging = authIn
  const routeProps = {authenticated, logging, config, user}
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {/*<CssBaseline />*/}
      {/*<div className={classes.root}>*/}
      <Switch>
        <HomeRoute exact path='/' component={ Login } {...routeProps} />
        <PublicRoute exact path={LOGIN_ROUTE} component={ Login } {...routeProps} />
        <PrivateRoute exact path={DASHBOARD_ROUTE} component={ Dashboard } {...routeProps} />
        <PrivateRoute exact path={PERSONAL_NEW_CUSTOMER_ROUTE} component={ NewCustomer } isBusiness={false} {...routeProps} />
        <PrivateRoute exact path={BUSINESS_DASHBOARD_ROUTE} component={ BusinessDashboard } {...routeProps} />
        <PrivateRoute exact path={BUSINESS_NEW_CUSTOMER_ROUTE} component={ NewCustomer } isBusiness={true} {...routeProps} />
        <PrivateRoute path={APPLICATION_ROUTE + ":uid"} component={Application} {...routeProps} />
        <PrivateRoute path={BUSINESS_APPLICATION_ROUTE + ":uid"} component={Company} {...routeProps} />
        {/*<PrivateRoute exact path='/business' component={ Business } {...routeProps} />*/}
      </Switch>
      {/*</div>*/}
    </ThemeProvider>
  )
}

export default connect(
  state => ({
    user: state.base.user,
    loggingIn: state.base.loggingIn,
    registeringIn: state.base.registeringIn,
    authIn: state.base.authIn
  }),
  { ...baseActions }
)(withStyles(styles)(Routes))

// <AppAppBar authenticated={authenticated} handleLogout={logout} />
// <Container maxWidth="md" className={classes.container}>
// <Paper className={classes.paper}>
// </Paper>
// </Container>
// <AppFooter />