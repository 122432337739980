import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ config, logging, authenticated, component, isBusiness, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (logging) return <div>Logging in...</div>
    return authenticated
      ? (React.createElement(component, { ...props, config, logging, authenticated, isBusiness }))
      : (<Redirect to={{pathname: '/', state: { from: props.location }}} />)
  }} />
)

export default PrivateRoute
