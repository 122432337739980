import React, {useMemo} from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import PrivateLayout from "../../layout/PrivateLayout";
import {useSelector} from "react-redux";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {BUSINESS_APPLICATION_ROUTE, BUSINESS_NEW_CUSTOMER_ROUTE} from "../../const";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom"
import {renderCountry} from "../../components";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 820
  },
  table: {
    backgroundColor: '#FFFFFF'
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.secondary.dark
  }
}))

const CellChip = React.memo((props) => <Chip color="primary" size="small" label={props.value}/>)
const CellBoolIcon = React.memo((props) => (
    props.value
      ? <CheckIcon color="primary" />
      : <ClearIcon color="action" />
  )
)
// const IconLinkButton = React.memo((props) => (
//   <IconButton color="primary" ><ExitToAppIcon /></IconButton>
//   ))
const renderChip = (params) => (
  params.value.map((cv, i) => <CellChip value={cv} key={i} />)
)
const renderBoolIcon = (params) => <CellBoolIcon value={params.value} />

const IconLink = (params) => {
  const history = useHistory()
  return (
    <IconButton color="primary" onClick={() => {history.push(BUSINESS_APPLICATION_ROUTE + params.value)}}>
      <ExitToAppIcon />
    </IconButton>
  )
}

const tableColumns = [
  // { headerName: 'ID', field: 'id' },
  { field: 'id', hide: true },
  {
    headerName: ' ', field: 'uid', width: 65, renderCell: IconLink,
    resizable: false, sortable: false, disableColumnMenu: true
  },
  { headerName: 'Company', field: 'entityName', width: 180 },
  { headerName: 'registrationNumber', field: 'registrationNumber', width: 210 },
  { headerName: 'Type', field: 'entityType', width: 120 },
  { headerName: 'registrationDate', field: 'registrationDate', width: 180 },
  { headerName: 'Status', field: 'status', width: 120 },
  {
    headerName: 'Client Of',
    field: 'companyName',
    renderCell: renderChip,
    width: 110
  },
  {
    headerName: 'Owners',
    field: 'owners',
    renderCell: renderChip,
    width: 110
  },
  {
    headerName: 'Tax Residence',
    field: 'taxResidence',
    renderCell: renderCountry,
    width: 123
  },
  {
    headerName: 'Completed?',
    field: 'isCompleted',
    renderCell: renderBoolIcon,
    width: 130,
  },
  {
    headerName: 'Checked?',
    field: 'isChecked',
    renderCell: renderBoolIcon,
    width: 120
  },
]


const BusinessDashboard = () => {
  const classes = useStyles()
  const businesses = useSelector((state) => state.base.businesses)
  const tableClients = useMemo(() => JSON.parse(JSON.stringify(businesses)), [businesses])
  return (
    <PrivateLayout>
      <div className={classes.root}>
        <div className={classes.header}>
          <Link component={RouterLink} to={BUSINESS_NEW_CUSTOMER_ROUTE}>
            New Company
          </Link>
        </div>
        <DataGrid
          rows={tableClients}
          columns={tableColumns}
          className={classes.table}
          pageSize={12}
          // onRowClick={({row}) => {history.push(APPLICATION_ROUTE + row.uid)}}
          showToolbar={false}
          sortModel={[
            {
              field: 'id',
              sort: 'desc',
            },
          ]}
        />
      </div>
    </PrivateLayout>
  )
}

export default BusinessDashboard